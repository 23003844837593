import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const TemplateView = Loadable(lazy(() => import('views/templates/view-templates')));
const TemplateViewCategory = Loadable(lazy(() => import('views/templates/view-templates-category')));
const TemplateSearchResults = Loadable(lazy(() => import('views/templates/view-templates-algolia')));
const EditTemplate = Loadable(lazy(() => import('views/templates/edit-template')));
const AccountManagement = Loadable(lazy(() => import('views/account-management')));
const CreateAccount = Loadable(lazy(() => import('views/account-management/create-account')));
const AccountSettings = Loadable(lazy(() => import('views/account-settings')));
const FreeTemplates = Loadable(lazy(() => import('views/free-templates')));

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/home" />
        },
        {
            path: '/home',
            element: <TemplateView />
        },
        {
            path: '/search',
            element: <TemplateSearchResults />
        },
        {
            path: '/templates/view',
            element: <TemplateViewCategory />
        },
        {
            path: '/edit-template/:templateID',
            element: <EditTemplate />
        },
        {
            path: '/account-management',
            element: <AccountManagement />
        },
        {
            path: '/create-account',
            element: <CreateAccount />
        },
        {
            path: '/account-settings',
            element: <AccountSettings />
        },
        {
            path: '/free-templates',
            element: <FreeTemplates />
        }
    ]
};

export default AdminRoutes;
