// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyAmsKJMAVA29UtQ34q7oPl63G7WYGt1OFk',
    authDomain: 'nbt-production.firebaseapp.com',
    projectId: 'nbt-production',
    storageBucket: 'nbt-production.appspot.com',
    messagingSenderId: '662403085263',
    appId: '1:662403085263:web:5a81c097a62a8cd33118ba'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions };
