import { useSelector } from 'react-redux';
// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import userTypes from '../../../../constants/userTypes';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const user = useSelector((state) => state.user);
    const userData = user.data;

    let menuItems = menuItem.items;

    if (userData.type === userTypes.Admin) {
        menuItems = menuItem.adminItems;
    }
    if (userData.type === userTypes.HealthPractitioner) {
        menuItems = menuItem.healthPractitionerItems;
    }

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <div>{navItems}</div>;
};

export default MenuList;
