import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/NewPassword')));
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication3/VerifyEmail')));
const ContactSupport = Loadable(lazy(() => import('views/pages/authentication/authentication3/ContactSupport')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        // {
        //     path: '/register',
        //     element: <AuthRegister3 />
        // },
        {
            path: '/reset-password',
            element: <ResetPassword />
        },
        {
            path: '/new-password/:userID',
            element: <NewPassword />
        },
        {
            path: '/email-verification/:userID',
            element: <VerifyEmail />
        },
        {
            path: '/contact-support',
            element: <ContactSupport />
        }
    ]
};

export default AuthenticationRoutes;
