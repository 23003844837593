// assets
import { IconLayoutGrid, IconCircleCheck, IconNotes, IconUser, IconPhoto } from '@tabler/icons';

import templateSubItems from './templateSubItems';

// constant
const icons = { IconLayoutGrid, IconCircleCheck, IconNotes, IconUser, IconPhoto };

// ==============================|| Health Practitioner MENU ITEMS ||============================== //

const healthPractitionerPages = {
    id: 'healthPractitionerPages',
    title: 'Health Practitioner Pages',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/home',
            icon: icons.IconLayoutGrid,
            breadcrumbs: false
        },
        {
            id: 'your-documents',
            title: 'Your Documents',
            type: 'item',
            url: '/your-documents',
            icon: icons.IconCircleCheck,
            breadcrumbs: false
        },
        {
            id: 'templates',
            title: 'Templates',
            type: 'collapse',
            icon: icons.IconNotes,
            children: templateSubItems
        },
        {
            id: 'contacts',
            title: 'Contacts',
            type: 'item',
            url: '/contacts',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'brandKit',
            title: 'Brand Kit',
            type: 'item',
            url: '/brand-kit',
            icon: icons.IconPhoto,
            breadcrumbs: false
        }
    ]
};

export default healthPractitionerPages;
