import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Onboarding = Loadable(lazy(() => import('views/onboarding')));

// ==============================|| Health Practitioner ROUTING ||============================== //

const NotOnboardedHealthPractitionerRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/onboarding',
            element: <Onboarding />
        },
        {
            path: '*',
            element: <Navigate to="/onboarding" />
        }
    ]
};

export default NotOnboardedHealthPractitionerRoutes;
