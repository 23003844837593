import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const YourDocuments = Loadable(lazy(() => import('views/your-documents')));
const TemplateView = Loadable(lazy(() => import('views/templates/view-templates')));
const TemplateViewCategory = Loadable(lazy(() => import('views/templates/view-templates-category')));
const TemplateSearchResults = Loadable(lazy(() => import('views/templates/view-templates-algolia')));
const EditTemplate = Loadable(lazy(() => import('views/templates/edit-template')));
const Contacts = Loadable(lazy(() => import('views/contacts')));
const ContactsUpload = Loadable(lazy(() => import('views/contacts/upload')));
const BrandKit = Loadable(lazy(() => import('views/brand-kit')));
const Onboarding = Loadable(lazy(() => import('views/onboarding')));
const SubscriptionPage = Loadable(lazy(() => import('views/subscription')));
const SubscriptionChange = Loadable(lazy(() => import('views/subscription-change')));
const PaymentMethodChange = Loadable(lazy(() => import('views/payment-method-change')));
const CheckoutPage = Loadable(lazy(() => import('views/subscription/checkout')));
const Invoices = Loadable(lazy(() => import('views/invoices')));
const PaymentMethods = Loadable(lazy(() => import('views/payment-methods')));
const AddCard = Loadable(lazy(() => import('views/payment-methods/add-card')));
const AccountSettings = Loadable(lazy(() => import('views/account-settings')));

// ==============================|| Health Practitioner ROUTING ||============================== //

const HealthPractitionerRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/home" />
        },
        {
            path: '/home',
            element: <TemplateView />
        },
        {
            path: '/search',
            element: <TemplateSearchResults />
        },
        {
            path: '/your-documents',
            element: <YourDocuments />
        },
        {
            path: '/templates/view',
            element: <TemplateViewCategory />
        },
        {
            path: '/edit-template/:templateID',
            element: <EditTemplate />
        },
        {
            path: '/contacts',
            element: <Contacts />
        },
        {
            path: '/contacts/upload',
            element: <ContactsUpload />
        },
        {
            path: '/brand-kit',
            element: <BrandKit />
        },
        {
            path: '/onboarding',
            element: <Onboarding />
        },
        {
            path: '/subscription',
            element: <SubscriptionPage />
        },
        {
            path: '/subscription-change',
            element: <SubscriptionChange />
        },
        {
            path: '/payment-method-change/:customerId/:subscriptionId',
            element: <PaymentMethodChange />
        },
        {
            path: '/checkout',
            element: <CheckoutPage />
        },
        {
            path: '/checkout/:promotionCodeId',
            element: <CheckoutPage />
        },
        {
            path: '/invoices',
            element: <Invoices />
        },
        {
            path: '/payment-methods',
            element: <PaymentMethods />
        },
        {
            path: '/payment-methods/add-card/:setupIntentSecret',
            element: <AddCard />
        },
        {
            path: '/account-settings',
            element: <AccountSettings />
        }
    ]
};

export default HealthPractitionerRoutes;
