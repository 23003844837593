// id of each plan also determines the tier
const subscriptionPlans = [
    {
        id: 'basic',
        name: 'Basic',
        price: 54,
        priceId: 'price_1L6RMdLSNzOOoFpYxIlRkv7E',
        productId: 'prod_Lo3TfgRYJCXZs9',
        color: 'rgba(246, 191, 176, 0.6)'
    },
    {
        id: 'professional',
        name: 'Professional',
        price: 74,
        priceId: 'price_1L6RMmLSNzOOoFpYqLVFbVUa',
        productId: 'prod_Lo3ThLfAMmFZqx',
        color: 'rgba(153, 204, 204, 0.6)'
    }
];

// default tax rates for subscription
const taxRates = ['txr_1LArcFLSNzOOoFpY9uWoblLy'];

// features of each paid plan
const subscriptionFeatures = [
    {
        title: 'Patient Handouts/Templates',
        subItems: ['Health Conditions', 'Dietary Guidelines', 'Lifestyle Guidlines', 'Questionnaires'],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Meal Plans & Recipes',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Infographics',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Questionnaires',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Patient Tools',
        subItems: ['Diaries', 'Planners'],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Business Tools',
        subItems: ['Patient Forms', 'Communication Templates', 'System Templates', 'Letter Templates', 'Scripts'],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Brand Kit',
        subItems: ['Upload logo', 'Add contact details', 'Apply brand colours to headings'],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Document Editor',
        subItems: ['Change fonts', 'Change text colours', 'Add text', 'Add headings', 'Upload images', 'Add dividers', 'Add buttons'],
        includedIn: {
            basic: false,
            professional: true
        }
    },
    {
        title: 'Template Customisation',
        subItems: ['Edit content'],
        includedIn: {
            basic: false,
            professional: true
        }
    },
    {
        title: 'Email',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Print',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Download',
        subItems: [],
        includedIn: {
            basic: true,
            professional: true
        }
    },
    {
        title: 'Save your Documents',
        subItems: [],
        includedIn: {
            basic: false,
            professional: true
        }
    }
];

export { subscriptionPlans, taxRates, subscriptionFeatures };
