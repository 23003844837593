import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// firestore
import { db } from 'firebase';
import { query, collection, onSnapshot, where } from 'firebase/firestore';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import UpgradePromptSection from './UpgradePromptSection';
import userTypes from 'constants/userTypes';
import { subscriptionPlans } from 'constants/subscription';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const user = useSelector((state) => state.user);
    const userData = user.data;

    // subscription tier
    const [subscriptionTier, setSubscriptionTier] = useState('free');
    const [loadingCustomer, setLoadingCustomer] = useState(true);

    useEffect(() => {
        let unsubscribe;

        if (userData.id && userData.type === userTypes.HealthPractitioner) {
            const q = query(collection(db, 'stripe_customers'), where('uid', '==', userData.id));
            unsubscribe = onSnapshot(q, (querySnapshot) => {
                const stripeCustomer = querySnapshot.docs[0];

                if (stripeCustomer) {
                    const { subscriptionStatus, subscriptionPeriodEnd, productId } = stripeCustomer.data();

                    if (subscriptionStatus && subscriptionPeriodEnd && productId) {
                        // check if subscription is active or canceled but still valid
                        if (
                            subscriptionStatus === 'active' ||
                            (subscriptionStatus === 'canceled' && new Date(subscriptionPeriodEnd * 1000).getTime() > new Date().getTime())
                        ) {
                            const tier = subscriptionPlans.filter((p) => p.productId === productId)[0]?.id || 'free';
                            setSubscriptionTier(tier);
                        }
                    }
                }
                setLoadingCustomer(false);
            });
        }
        if (userData.type === userTypes.Admin) {
            setLoadingCustomer(false);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userData]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.nbt.mutedGreen,
                            color: theme.palette.nbt.blue,
                            '&:hover': {
                                border: `1px solid ${theme.palette.nbt.blue}`
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* upgrade prompt for free hp */}
            {!loadingCustomer && subscriptionTier === 'free' && userData.type === userTypes.HealthPractitioner && <UpgradePromptSection />}

            {/* user profile */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
