// assets
import { IconLayoutGrid, IconUsers, IconNotes, IconFileCertificate } from '@tabler/icons';

import templateSubItems from './templateSubItems';

// constant
const icons = { IconLayoutGrid, IconUsers, IconNotes, IconFileCertificate };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const adminPages = {
    id: 'adminPages',
    title: 'Admin Pages',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/home',
            icon: icons.IconLayoutGrid,
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: 'Accounts',
            type: 'item',
            url: '/account-management',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'templates',
            title: 'Templates',
            type: 'collapse',
            icon: icons.IconNotes,
            children: templateSubItems
        },
        {
            id: 'free-templates',
            title: 'Free Templates',
            type: 'item',
            url: '/free-templates',
            icon: icons.IconFileCertificate,
            breadcrumbs: false
        }
    ]
};

export default adminPages;
