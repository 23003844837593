// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: {}
};

// ===========================|| USER REDUCER ||=========================== //

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA:
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
};

export default userReducer;
