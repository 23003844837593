import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import adminPages from './adminPages';
import healthPractitionerPages from './healthPractitionerPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pages, utilities, other],
    adminItems: [adminPages],
    healthPractitionerItems: [healthPractitionerPages]
};

export default menuItems;
