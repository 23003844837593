// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Link } from '@mui/material';

// ==============================|| Upgrade Prompt ||============================== //

const UpgradePromptSection = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                ml: 2,
                mr: 3,
                [theme.breakpoints.down('md')]: {
                    mr: 2
                }
            }}
        >
            <Typography>
                You are currently on a free trial. &nbsp;
                <Link href="/subscription" color="primary">
                    Upgrade to access paid features
                </Link>
            </Typography>
        </Box>
    );
};

export default UpgradePromptSection;
