import categoryTypes from 'constants/categoryTypes';

const templateSubItems = categoryTypes.map((category) => {
    const path = `templates/view?category=${encodeURIComponent(category)}`;
    return {
        id: path,
        title: category,
        type: 'item',
        url: `/${path}`,
        breadcrumbs: false
    };
});

export default templateSubItems;
