import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from './AdminRoutes';
import userTypes from 'constants/userTypes';
import HealthPractitionerRoutes from './HealthPractitionerRoutes';
import NotOnboardedHealthPractitionerRoutes from './NotOnboardedHealthPractitionerRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ user, userType }) {
    let routes = [AuthenticationRoutes];
    const thisUser = useSelector((state) => state.user);
    const userData = thisUser.data;

    if (user) {
        if (userType === userTypes.Admin) {
            routes = [AdminRoutes, AuthenticationRoutes];
        } else if (userType === userTypes.HealthPractitioner && !userData.isOnboardingCompleted) {
            routes = [NotOnboardedHealthPractitionerRoutes, AuthenticationRoutes];
        } else if (userType === userTypes.HealthPractitioner && userData.isOnboardingCompleted) {
            routes = [HealthPractitionerRoutes, AuthenticationRoutes];
        } else {
            routes = [AuthenticationRoutes];
        }
    }
    return useRoutes(routes);
}
